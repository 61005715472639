<template>
  <div class="w-full flex items-center justify-center">
    <div class="bg-white p-24 rounded-xl">
      <img
        src="../../assets/images/thank-you.gif"
        class="border border-gray-400 rounded mx-auto object-cover"
        style="height: 400px"
      >
      <div
        class="w-full text-center mt-4 text-4xl font-semibold"
        style="width: 450px"
      >
        Thanks for Subscribing to foreplay
      </div>
      <router-link :to="{ name: 'LibraryView' }">
        <button class="border-none bg-blue-700 hover:bg-blue-600 text-white rounded w-full text-lg p-3 mt-4">
          Return to foreplay
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ThankYouRedirectView',
  metaInfo: {
    title: 'Foreplay.co | Thank you!'
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getUserSubscription'])
  },
  mounted () {
    try {
      const subscriptionId = this.getUserSubscription.id
      let totalOrderPrice = 0

      const layerItems = this.getUserSubscription.items.map((item) => {
        const itemProduct = item?.price?.product
        totalOrderPrice += Number(itemProduct?.metadata?.monthlyPrice)
        return {
          item_name: itemProduct.name,
          item_id: itemProduct.id,
          price: Number(itemProduct?.metadata?.monthlyPrice),
          quantity: item.quantity
        }
      })
      const userData = {
        email: this.getUser.email,
        address: {
          first_name: this.getUser.first_name,
          last_name: this.getUser.last_name,
          country: '',
          postal_code: ''
        }
      }

      window.dataLayer.push({
        event: 'purchase',
        user_data: {
          ...userData
        },
        ecommerce: {
          transaction_id: subscriptionId,
          value: totalOrderPrice,
          currency: 'USD',
          items: layerItems
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
}
</script>

<style scoped lang="sass"></style>
